import format from 'date-fns/format';

/**
 * @param {string} date
 * @param {string} custom
 * @return {string}
 */
// eslint-disable-next-line import/prefer-default-export
export function toDate(date, custom) {
  if (!date) return format(new Date(), custom);

  return format(new Date(date), custom);
}
